import { Match } from "@/models/match";
import { HttpService } from "./http.service";

export class ReplayService {
  public static uploadReplays(matchSlug: string, etternaXMLFile: File, replayFiles: File[]): Promise<void> {
    const formData = new FormData();
    formData.append('etternaXML', etternaXMLFile);
    for (const replayFile of replayFiles) {
      formData.append('replay', replayFile);
    }

    return HttpService.post(`replay/submit/${matchSlug}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
  }

  public static getMatchesWithReplays(): Promise<Match[]> {
    return HttpService.get('replay');
  }
}
