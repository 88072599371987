
import { User } from '@/models/user';
import { State } from '@/store';
import { Options, Vue } from 'vue-class-component';
import { useStore } from 'vuex';

@Options({})
export default class Navbar extends Vue {
  discordUrl = '';
  store = useStore<State>();

  get user(): User | null {
    return this.store.state.user;
  }

  beforeMount(): void {
    const urlParams = new URLSearchParams();
    urlParams.append('client_id', process.env.VUE_APP_DISCORD_CLIENT_ID);
    urlParams.append('redirect_uri', process.env.VUE_APP_DISCORD_REDIRECT_URI);
    urlParams.append('response_type', 'code');
    urlParams.append('scope', 'identify guilds');
    urlParams.append('prompt', 'none');

    this.discordUrl = `https://discord.com/api/oauth2/authorize?${urlParams.toString()}`;
  }

  logout(): void {
    this.store.commit('setUser', null);
    localStorage.removeItem('token');
  }
}
