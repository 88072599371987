
import { Options, Vue } from 'vue-class-component';
import { useRoute } from 'vue-router';
import axios from 'axios';
import { HttpService } from '@/services/http.service';
import { UserService } from '@/services/user.service';
import { useStore } from 'vuex';
import { State } from '@/store';

@Options({})
export default class Discord extends Vue {
  state: 'verifying' | 'loggedIn' | 'error' = 'verifying';
  private route = useRoute();
  private store = useStore<State>();

  get username(): string | undefined {
    return this.store.state.user?.username;
  }

  async mounted(): Promise<void> {
    if (!this.route.query.code || localStorage.getItem('token') != null) {
      this.$router.push({ name: 'home' });
      return;
    }

    const url = `${process.env.VUE_APP_API_URL}/auth/login?code=${this.route.query.code}`;

    let accessToken: string;
    try {
      accessToken = (await axios.get(url)).data.accessToken;

    } catch ( e ) {
      this.state = 'error';

      return;
    }

    localStorage.setItem('token', accessToken);

    HttpService.updateAxiosInstance();

    if (!(await UserService.loadUserData())) {
      this.state = 'error';
      return;
    }

    setTimeout(() => {
      this.state = 'loggedIn';
      setTimeout(() => {
        this.$router.push({ name: 'home' });
      }, 1000);
    }, 500);
  }

}
