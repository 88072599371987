<template>
  <div class="container mx-auto py-4">
    <h1 class="text-3xl font-bold mb-4">Match Replays</h1>
    <div v-for="match of matches" :key="match.slug" class="mb-2 bg-gray-400 px-3 py-2 flex justify-between items-center">
      <span><strong>{{ match.name }}</strong> ({{ formatDateTime(match.dateTime) }})</span>
      <a class="button bg-green-400 hover:bg-green-500" :href="apiUrl + '/replay/' + match.slug">Download</a>
    </div>
  </div>
</template>

<script lang="ts">
import { Match } from '@/models/match';
import { HttpService } from '@/services/http.service';
import { ReplayService } from '@/services/replay.service';
import moment from 'moment';
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class Replays extends Vue {
  matches: Match[] = [];

  public get apiUrl(): string {
    return HttpService.getApiUrl();
  }

  public async created(): Promise<void> {
    try {
      this.matches = (await ReplayService.getMatchesWithReplays()).map(match => {
        return {
          ...match,
          dateTime: moment(match.dateTime) as any
        }
      });
    } catch (e) {
      return;
    }
  }

  public formatDateTime(dateTime: moment.Moment): string {
    return dateTime.format('DD.MM.YYYY HH:mm');
  }
}
</script>

<style scoped lang="scss">
</style>
