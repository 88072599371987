import { createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue';
/*import Tournament from '../views/Tournament.vue';
import Tournaments from '../views/Tournaments.vue';
import TournamentForm from '../views/TournamentForm.vue';*/
import Discord from '../views/Discord.vue';
import SubmitReplay from '../views/SubmitReplay.vue';
import Replays from '../views/Replays.vue';
import CreateMatch from '../views/CreateMatch.vue';
import store from '@/store';

const routeGuardIsAdmin = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  if (store.state.user == null || !store.state.user.isAdmin) next({ name: 'home' });
  else next();
};

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  /*{
    path: '/tournaments/:slug',
    name: 'tournament',
    component: Tournament
  },
  {
    path: '/tournaments',
    name: 'tournaments',
    component: Tournaments
  },
  {
    path: '/tournaments/new',
    name: 'tournaments-new',
    component: TournamentForm,
    beforeEnter: routeGuardIsAdmin
  },*/
  {
    path: '/auth/discord',
    name: 'discord',
    component: Discord
  },
  {
    path: '/replay/submit/:slug',
    name: 'submitReplay',
    component: SubmitReplay
  },
  {
    path: '/replay',
    name: 'replay',
    component: Replays,
    beforeEnter: routeGuardIsAdmin
  },
  {
    path: '/match/create',
    name: 'createMatch',
    component: CreateMatch,
    beforeEnter: routeGuardIsAdmin
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
