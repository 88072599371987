import { HttpService } from "./http.service";

export class ChartpoolService {
  public static async getAll(): Promise<any> {
    return HttpService.get(`chartpool`);
  }

  public static async getCharts(id: number): Promise<any> {
    return HttpService.get(`chartpool/${id}/charts`);
  }
}
