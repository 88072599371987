<template>
  <div class="container mx-auto py-4">
    <div v-if="error != null" class="bg-red-400 px-3 py-2 font-bold">{{ error }}</div>

    <h1 class="text-3xl font-bold">Create Match</h1>
    <input type="text" placeholder="Match Name (e.g. Masaniger vs Prefix)" v-model="matchName"
      class="px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-white bg-white rounded text-sm border border-gray-400 outline-none focus:outline-none focus:ring w-full mb-2" />

      <input type="text" placeholder="Date and Time (e.g. 2021-06-21 12:34) [Match Start]" v-model="dateTime"
        class="px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-white bg-white rounded text-sm border border-gray-400 outline-none focus:outline-none focus:ring w-full mb-2" />

    <label class="block text-left mb-2" style="max-width: 400px;">
      <span>Chartpool</span>
      <select class="form-select block w-full mt-1 text-gray-700" v-model="selectedChartpool" @change="getChartsForChartpool">
          <option v-for="chartpool of chartpools" :key="chartpool.name" :value="chartpool.id">{{ chartpool.name }}</option>
      </select>
    </label>

    <label class="block text-left mb-2" style="max-width: 600px">
      <span>Charts</span>
      <select style="height: 450px" class="form-multiselect block w-full mt-1 text-gray-700" v-model="selectedCharts" multiple>
        <option v-for="chart of charts" :key="chart.chartkey" :value="chart.chartkey">{{ chart.title }}</option>
      </select>
    </label>

    <button class="button bg-green-400 hover:bg-green-500" @click="create" :disabled="matchName === '' || dateTime === '' || selectedCharts.length === 0">Create</button>
  </div>
</template>

<script lang="ts">
import { MatchService } from '@/services/match.service';
import moment from 'moment';
import { Options, Vue } from 'vue-class-component';
import { ChartpoolService } from '../services/chartpool.service';

@Options({})
export default class CreateMatch extends Vue {
  matchName = '';
  dateTime = '';
  chartpools: any[] = [];
  selectedChartpool: any = null;
  charts: any[] = [];
  selectedCharts: any[] = [];
  error: string | null = null;

  public async beforeMount(): Promise<void> {
    try {
      this.chartpools = (await ChartpoolService.getAll()).sort((a: any, b: any) => {
        a = a.name.toLowerCase();
        b = b.name.toLowerCase();
        if (a > b) {
          return 1;
        } else if (a < b) {
          return -1;
        }

        return 0;
      });
    } catch (e) {
      this.error = 'Could not load chartpools';
      console.log(e);
      return;
    }

    this.selectedChartpool = this.chartpools[0].id;
    this.getChartsForChartpool();
  }

  public async getChartsForChartpool(): Promise<void> {
    try {
      this.charts = (await ChartpoolService.getCharts(this.selectedChartpool)).sort((a: any, b: any) => {
        a = a.title.toLowerCase();
        b = b.title.toLowerCase();
        if (a > b) {
          return 1;
        } else if (a < b) {
          return -1;
        }

        return 0;
      });
    } catch (e) {
      console.log(e);
      this.error = 'Could not load charts for chartpool';
      return;
    }

    this.selectedCharts = [];
  }

  public async create(): Promise<void> {
    const parsedDateTime = moment(this.dateTime, 'YYYY-MM-DD HH:mm', true).toISOString();
    if (parsedDateTime == null) {
      this.error = 'DateTime is not in a correct format';
      return;
    }

    let matchSlug: string;
    try {
      matchSlug = await MatchService.createMatch(this.matchName, parsedDateTime, this.selectedChartpool, this.selectedCharts);
    } catch (e) {
      console.log(e);
      this.error = 'Could not create match';
      return;
    }

    this.$router.push({ name: 'submitReplay', params: { slug: matchSlug } });
  }
}
</script>

<style lang="scss" scoped>
.button {
  &:disabled {
    @apply bg-green-200;
  }
}
</style>
