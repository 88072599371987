import { User } from '@/models/user'
import { createStore } from 'vuex'

export interface State {
  user: User | null;
}

export default createStore<State>({
  state: {
    user: null
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    }
  },
  actions: {
  },
  modules: {
  }
})
