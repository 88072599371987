<template>
  <div>
    Home Comp
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import type {} from 'wicg-file-system-access';

@Options({})
export default class Home extends Vue {

  public async openDir(): Promise<void> {
    const dirHandle = await window.showDirectoryPicker();
    for await (const entry of dirHandle.values()) {
      console.log(entry);
    }
  }
}
</script>
