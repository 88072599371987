
import { Match } from '@/models/match';
import { HttpService } from '@/services/http.service';
import { ReplayService } from '@/services/replay.service';
import moment from 'moment';
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class Replays extends Vue {
  matches: Match[] = [];

  public get apiUrl(): string {
    return HttpService.getApiUrl();
  }

  public async created(): Promise<void> {
    try {
      this.matches = (await ReplayService.getMatchesWithReplays()).map(match => {
        return {
          ...match,
          dateTime: moment(match.dateTime) as any
        }
      });
    } catch (e) {
      return;
    }
  }

  public formatDateTime(dateTime: moment.Moment): string {
    return dateTime.format('DD.MM.YYYY HH:mm');
  }
}
