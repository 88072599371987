<template>
  <div class="modal-wrapper">
    <div class="modal">
      <div class="modal-header">
        <slot name="header"></slot>
      </div>
      <div class="modal-body">
        <slot></slot>
      </div>
      <div class="modal-footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class Modal extends Vue {
}
</script>

<style scoped lang="scss">
.modal-wrapper {
  @apply bg-gray-500 mx-auto inline-flex justify-center items-start bg-opacity-50;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;

  .modal {
    @apply bg-gray-700 my-32 shadow-lg;

    .modal-header, .modal-body {
      @apply p-3;
    }

    .modal-header {
      @apply border-b-2 border-white;
    }
  }
}

</style>
