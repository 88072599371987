
import { MatchService } from '@/services/match.service';
import moment from 'moment';
import { Options, Vue } from 'vue-class-component';
import { ChartpoolService } from '../services/chartpool.service';

@Options({})
export default class CreateMatch extends Vue {
  matchName = '';
  dateTime = '';
  chartpools: any[] = [];
  selectedChartpool: any = null;
  charts: any[] = [];
  selectedCharts: any[] = [];
  error: string | null = null;

  public async beforeMount(): Promise<void> {
    try {
      this.chartpools = (await ChartpoolService.getAll()).sort((a: any, b: any) => {
        a = a.name.toLowerCase();
        b = b.name.toLowerCase();
        if (a > b) {
          return 1;
        } else if (a < b) {
          return -1;
        }

        return 0;
      });
    } catch (e) {
      this.error = 'Could not load chartpools';
      console.log(e);
      return;
    }

    this.selectedChartpool = this.chartpools[0].id;
    this.getChartsForChartpool();
  }

  public async getChartsForChartpool(): Promise<void> {
    try {
      this.charts = (await ChartpoolService.getCharts(this.selectedChartpool)).sort((a: any, b: any) => {
        a = a.title.toLowerCase();
        b = b.title.toLowerCase();
        if (a > b) {
          return 1;
        } else if (a < b) {
          return -1;
        }

        return 0;
      });
    } catch (e) {
      console.log(e);
      this.error = 'Could not load charts for chartpool';
      return;
    }

    this.selectedCharts = [];
  }

  public async create(): Promise<void> {
    const parsedDateTime = moment(this.dateTime, 'YYYY-MM-DD HH:mm', true).toISOString();
    if (parsedDateTime == null) {
      this.error = 'DateTime is not in a correct format';
      return;
    }

    let matchSlug: string;
    try {
      matchSlug = await MatchService.createMatch(this.matchName, parsedDateTime, this.selectedChartpool, this.selectedCharts);
    } catch (e) {
      console.log(e);
      this.error = 'Could not create match';
      return;
    }

    this.$router.push({ name: 'submitReplay', params: { slug: matchSlug } });
  }
}
