<template>
  <div class="container mx-auto py-4">
    <div v-if="state === 'verifying'" class="info-box">
      <h1 class="info-text">Logging In</h1>
      <fa-icon icon="circle-notch" class="fa-spin" size="5x"/>
    </div>
    <div v-if="state === 'loggedIn'" class="info-box">
      <h1 class="info-text">Welcome {{ username }}!</h1>
      <fa-icon :icon="['far', 'check-circle']" size="5x" class="text-green-400"/>
    </div>
    <div v-if="state === 'error'" class="info-box">
      <h1 class="info-text">Something went wrong</h1>
      <fa-icon :icon="['far', 'times-circle']" size="5x" class="text-red-400"/>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { useRoute } from 'vue-router';
import axios from 'axios';
import { HttpService } from '@/services/http.service';
import { UserService } from '@/services/user.service';
import { useStore } from 'vuex';
import { State } from '@/store';

@Options({})
export default class Discord extends Vue {
  state: 'verifying' | 'loggedIn' | 'error' = 'verifying';
  private route = useRoute();
  private store = useStore<State>();

  get username(): string | undefined {
    return this.store.state.user?.username;
  }

  async mounted(): Promise<void> {
    if (!this.route.query.code || localStorage.getItem('token') != null) {
      this.$router.push({ name: 'home' });
      return;
    }

    const url = `${process.env.VUE_APP_API_URL}/auth/login?code=${this.route.query.code}`;

    let accessToken: string;
    try {
      accessToken = (await axios.get(url)).data.accessToken;

    } catch ( e ) {
      this.state = 'error';

      return;
    }

    localStorage.setItem('token', accessToken);

    HttpService.updateAxiosInstance();

    if (!(await UserService.loadUserData())) {
      this.state = 'error';
      return;
    }

    setTimeout(() => {
      this.state = 'loggedIn';
      setTimeout(() => {
        this.$router.push({ name: 'home' });
      }, 1000);
    }, 500);
  }

}
</script>

<style scoped lang="scss">
.info-box {
  @apply flex flex-col items-center;

  .info-text {
    @apply text-5xl font-bold m-10;
  }
}
</style>
