import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

export class HttpService {

  private static axiosInstance: AxiosInstance;

  private static apiUrl = process.env.VUE_APP_API_URL;

  public static getApiUrl(): string {
    return this.apiUrl;
  }

  public static init(): void {
    this.updateAxiosInstance();
  }

  public static updateAxiosInstance(): void {
    const token = localStorage.getItem('token');

    if (token != null) {
      this.axiosInstance = axios.create({
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    } else {
      this.axiosInstance = axios.create();
    }
  }

  public static async get<T>(url: string, requestConfig?: AxiosRequestConfig): Promise<T> {
    return (await this.axiosInstance.get(`${this.apiUrl}/${url}`, requestConfig)).data;
  }

  // eslint-disable-next-line
  public static async post<T>(url: string, data?: any, requestConfig?: AxiosRequestConfig): Promise<T> {
    return (await this.axiosInstance.post(`${this.apiUrl}/${url}`, data, requestConfig)).data;
  }

  public static async delete(url: string, requestConfig?: AxiosRequestConfig): Promise<void> {
    return this.axiosInstance.delete(`${this.apiUrl}/${url}`, requestConfig);
  }
}
