import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Navbar from './components/Navbar.vue';
import Modal from './components/Modal.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { HttpService } from './services/http.service';
import { UserService } from './services/user.service';

library.add(faDiscord, faCircleNotch, faCheckCircle, faTimesCircle);

(async () => {
  HttpService.init();
  await UserService.init();

  createApp(App)
    .use(store)
    .use(router)
    .component('ma-navbar', Navbar)
    .component('ma-modal', Modal)
    .component('fa-icon', FontAwesomeIcon)
    .mount('#app');
})();
