<template>
  <nav class="dark:bg-gray-800 shadow-lg flex items-center px-16" style="width: 100vw; height: 50px">
    <span class="brand">Mania Austria</span>
    <div>
      <!--<router-link :to="{ name: 'tournaments' }">Tournaments</router-link>-->
    </div>
    <div class="flex-auto"></div>
    <div>
      <a v-if="user == null" class="discord-login" :href="discordUrl">Login with Discord <fa-icon :icon="['fab', 'discord']"/></a>
      <div v-if="user != null" class="flex items-center">
        <img class="object-contain rounded-full" style="height: 30px; display: inline-block" :src="'https://cdn.discordapp.com/avatars/' + user.discordId + '/' + user.discordAvatar + '.png?size=64'"/>
        <span class="cursor-pointer ml-2" @click="logout">{{ user.username }}</span>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { User } from '@/models/user';
import { State } from '@/store';
import { Options, Vue } from 'vue-class-component';
import { useStore } from 'vuex';

@Options({})
export default class Navbar extends Vue {
  discordUrl = '';
  store = useStore<State>();

  get user(): User | null {
    return this.store.state.user;
  }

  beforeMount(): void {
    const urlParams = new URLSearchParams();
    urlParams.append('client_id', process.env.VUE_APP_DISCORD_CLIENT_ID);
    urlParams.append('redirect_uri', process.env.VUE_APP_DISCORD_REDIRECT_URI);
    urlParams.append('response_type', 'code');
    urlParams.append('scope', 'identify guilds');
    urlParams.append('prompt', 'none');

    this.discordUrl = `https://discord.com/api/oauth2/authorize?${urlParams.toString()}`;
  }

  logout(): void {
    this.store.commit('setUser', null);
    localStorage.removeItem('token');
  }
}
</script>

<style scoped lang="scss">
.brand {
  @apply text-xl font-bold mr-16;
}

.discord-login {
  @apply py-1 px-2 shadow transition;
  background-color: $color-discord-blurple;
  &:hover {
    background-color: darken($color-discord-blurple, 5%);
  }
}
</style>
