import { User } from "@/models/user";
import store from "@/store";
import { HttpService } from "./http.service";

export class UserService {

  public static async init(): Promise<void> {
    if (!(await this.loadUserData())) {
      localStorage.removeItem('token');
      HttpService.updateAxiosInstance();
    }
  }

  public static async loadUserData(): Promise<boolean> {
    let userData: User;
    try {
      userData = await HttpService.get<User>('user/me');
    } catch (e) {
      return false;
    }

    store.commit('setUser', userData);

    return true;
  }
}
