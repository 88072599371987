import { Match, MatchHasReplay } from "@/models/match";
import { HttpService } from "./http.service";

export class MatchService {
  public static async getBySlug(slug: string): Promise<Match> {
    return HttpService.get(`match/${encodeURIComponent(slug)}`);
  }

  public static async getBySlugHasReplay(slug: string): Promise<MatchHasReplay> {
    return HttpService.get(`match/${encodeURIComponent(slug)}/replay`);
  }

  public static async createMatch(name: string, dateTime: string, chartpoolId: number, chartKeys: string[]): Promise<string> {
    return HttpService.post(`match/create`, { name, dateTime, chartpoolId, chartKeys });
  }
}
