<template>
  <div class="container mx-auto py-4">
    <div v-if="error != null" class="bg-red-400 px-3 py-2 font-bold">{{ error }}</div>
    <div v-if="user == null && error == null">Please login to continue!</div>
    <div v-if="user != null && error == null && match != null">
      <h1 class="text-xl font-bold">Submit replay for "{{ match?.name }}"</h1>
      <h3>Date of match: {{ formatDateTime(match?.dateTime) }}</h3>
      <button v-if="state === 'openDir'" class="button bg-green-400 hover:bg-green-500" @click="openEtternaDir">Open Etterna Directory</button>

      <div v-else-if="state === 'profileSelect'">
        <h3>Select Profile:</h3>
        <button class="button bg-green-400 hover:bg-green-500 mr-2" v-for="profile of profiles" :key="profile.handle" @click="parseXML(profile.handle)">{{ profile.name }}</button>
      </div>

      <div v-else-if="state === 'parsing'">
        <span v-if="currentlyParsing === ''">Loading Etterna.xml</span>
        <span v-if="currentlyParsing !== ''">Getting scores ({{ progressParsing }}/{{ chartsLength }}): {{ currentlyParsing }}</span>
      </div>

      <div v-else-if="state === 'choosing'">
        <h3 class="font-bold">Select score for "{{ currentChartTitle }}"</h3>
        <input id="show-only-relevant" type="checkbox" v-model="showOnlyRelevant">
        <label class="ml-1" for="show-only-relevant">Show only relevant scores</label>
        <div class="flex flex-col items-start mb-2">
          <span v-if="currentChartScores.length === 0">No scores available</span>
          <div class="bg-gray-500 hover:bg-gray-400 px-3 py-2 mb-2 cursor-pointer score" v-for="score of currentChartScores" :key="score.scoreKey" @click="selectScoreKey(score.scoreKey)" :class="{ active: score.scoreKey === selectedScoreKey }">
            <strong>Played at:</strong> {{ formatDateTime(score.dateTime) }} <strong>Acc:</strong> {{ score.acc }} <strong>Rate:</strong> {{ score.rate }}
          </div>
        </div>
        <button v-if="choosingIndex !== 0" class="button bg-red-400 hover:bg-red-500 mr-2" @click="choosePreviousChart">Back</button>
        <button :disabled="selectedScoreKey == null && currentChartScores.length !== 0" class="button bg-green-400 hover:bg-green-500 next-button" @click="chooseNextChart">Next</button>
      </div>

      <div v-else-if="state === 'upload'">
        <button class="button bg-red-400 hover:bg-red-500 mr-2" @click="uploadBack">Back</button>
        <button class="button bg-green-400 hover:bg-green-500" @click="upload">Upload Replays</button>
      </div>

      <div v-else-if="state === 'uploading'" class="font-bold">Uploading replays...</div>

      <div v-else-if="state === 'uploadDone'" class="font-bold">Replays uploaded successfully</div>
    </div>
  </div>
</template>

<script lang="ts">
import { State } from '@/store';
import { toRaw } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { MatchHasReplay } from '../models/match';
import { MatchService } from '../services/match.service';
import { ReplayService } from '../services/replay.service';
import { parse as parseIni } from 'ini';
import ParseXMLWorker from 'worker-loader!../worker/parse-xml.worker';
import moment from 'moment';

@Options({})
export default class SubmitReplay extends Vue {
  match: MatchHasReplay | null = null;
  profiles: Array<{ name: string; handle: FileSystemDirectoryHandle; }> = [];
  currentlyParsing = '';
  state: 'openDir' | 'profileSelect' | 'parsing' | 'choosing' | 'upload' | 'uploading' | 'uploadDone' = 'openDir';
  progressParsing = 0;
  scoresPerChart: { [index: string]: { title: string; scores: Array<{ rate: string; acc: number; dateTime: moment.Moment; scoreKey: string; }> } } = {};
  choosingIndex = 0;
  selectedScoreKeys: string[] = [];
  saveDirHandle: FileSystemDirectoryHandle | null = null;
  profileDirHandle: FileSystemDirectoryHandle | null = null;
  error: string | null = null;
  showOnlyRelevant = true;

  private route = useRoute();
  private store = useStore<State>();
  private etternaDir?: FileSystemDirectoryHandle;

  public get chartsLength(): number {
    return this.match?.charts.length ?? 0;
  }

  public get currentChartTitle(): string {
    return this.match?.charts[this.choosingIndex].title ?? '';
  }

  public get currentChartScores(): any {
    if (this.match == null) {
      return null;
    }

    let scores = this.scoresPerChart[this.match.charts[this.choosingIndex].chartkey].scores;

    if (this.showOnlyRelevant) {
      scores = scores.filter(score => score.dateTime.isSameOrAfter(this.match!.dateTime));
    }

    return scores.sort((a, b) => {
      if (a.dateTime.isAfter(b.dateTime)) {
        return -1;
      } else if (a.dateTime.isBefore(b.dateTime)) {
        return 1;
      }

      return 0;
    });
  }

  public get selectedScoreKey(): string {
    return this.selectedScoreKeys[this.choosingIndex];
  }

  private get user() {
    return this.store.state.user;
  }

  public formatDateTime(dateTime: moment.Moment): string {
    return dateTime.format('DD.MM.YYYY HH:mm');
  }

  public async created(): Promise<void> {
    if (window.showDirectoryPicker == null) {
      this.error = 'Please use an up to date version of Chrome or Edge';
      return;
    }

    const slug = this.route.params.slug as string;

    if (this.user == null) {
      return;
    }

    if (slug == null) {
      this.$router.push({ name: 'home' });
      return;
    }

    try {
      const match = await MatchService.getBySlugHasReplay(slug);
      match.dateTime = moment(match.dateTime) as any;
      this.match = match;
    } catch (e) {
      this.error = 'Could not load match data';
      return;
    }

    if (this.match.hasReplay) {
      this.error = 'You already submitted replays';
      return;
    }

    this.selectedScoreKeys = new Array(this.match.charts.length);
  }

  public async openEtternaDir(): Promise<void> {
    try {
      this.etternaDir = await showDirectoryPicker();
    } catch (e) {
      // noop
      return;
    }

    this.state = 'profileSelect';

    try {
      this.saveDirHandle = await this.etternaDir.getDirectoryHandle('Save');
    } catch (e) {
      console.log(e);
      this.error = 'Could not open Save directory';
      return;
    }

    let localProfilesHandle: FileSystemDirectoryHandle;
    try {
      localProfilesHandle = await this.saveDirHandle.getDirectoryHandle('LocalProfiles');
    } catch (e) {
      console.log(e);
      this.error = 'Could not open LocalProfiles directory';
      return;
    }

    for await (const entry of localProfilesHandle.values()) {
      let profileHandle: FileSystemDirectoryHandle;
      try {
        profileHandle = await localProfilesHandle.getDirectoryHandle(entry.name);
      } catch (e) {
        console.log(e);
        this.error = `Could not get Profile "${entry.name}"`;
        return;
      }

      let editableIni: { [key: string]: any };
      try {
        editableIni = parseIni(await (await (await profileHandle.getFileHandle('Editable.ini')).getFile()).text());
      } catch (e) {
        console.log(e);
        this.error = 'Could not read Editable.ini';
        return;
      }

      if (editableIni.Editable != null) {
        this.profiles.push({ handle: profileHandle, name: editableIni.Editable.DisplayName });
      }
    }
  }

  public parseXML(handle: FileSystemDirectoryHandle): void {
    this.state = 'parsing';

    if (this.match == null) {
      return;
    }

    this.profileDirHandle = handle;

    const worker = new ParseXMLWorker();
    worker.addEventListener('message', event => {
      if (event.data.msgType === 'parsing') {
        this.currentlyParsing = event.data.data;
        this.progressParsing++;
      } else if (event.data.msgType === 'done') {
        worker.terminate();

        for (const key of Object.keys(event.data.data)) {
          event.data.data[key].scores = event.data.data[key].scores.map((score: any) => {
            return {
              ...score,
              dateTime: moment(score.dateTime)
            };
          });
        }

        this.scoresPerChart = event.data.data;
        this.selectIfOnlySingleScore();
        this.state = 'choosing';
      } else if (event.data.msgType === 'error') {
        this.error = event.data.data;
        worker.terminate();
      }
    });

    worker.postMessage({ handle , charts: this.match.charts.map(chart => toRaw(chart)) });
  }

  public chooseNextChart(): void {
    if (this.match == null) {
      return;
    }

    this.selectedScoreKeys[this.choosingIndex] = this.selectedScoreKey;

    if (this.choosingIndex + 1 === this.match.charts.length) {
      this.state = 'upload';
      return;
    }

    this.choosingIndex++;

    this.selectIfOnlySingleScore();
  }

  private selectIfOnlySingleScore() {
    if (this.selectedScoreKey == null && this.currentChartScores != null && this.currentChartScores?.length === 1) {
      this.selectScoreKey(this.currentChartScores[0].scoreKey);
    }
  }

  public choosePreviousChart(): void {
    this.choosingIndex--;
  }

  public selectScoreKey(scoreKey: string): void {
    this.selectedScoreKeys[this.choosingIndex] = scoreKey;
  }

  public async upload(): Promise<void> {
    if (this.saveDirHandle == null || this.profileDirHandle == null || this.match == null) {
      return;
    }

    this.state = 'uploading';

    let etternaXML: File;
    try {
      etternaXML = await (await this.profileDirHandle.getFileHandle('Etterna.xml')).getFile();
    } catch (e) {
      console.log(e);
      this.error = 'Could not get Etterna.xml';
      return;
    }

    let replayDirHandle: FileSystemDirectoryHandle;
    try {
      replayDirHandle = await this.saveDirHandle.getDirectoryHandle('ReplaysV2');
    } catch (e) {
      console.log(e);
      this.error = 'Could not open ReplaysV2 directory';
      return;
    }

    const replayFiles: File[] = [];
    for (const selectedKey of this.selectedScoreKeys) {
      if (selectedKey == null) {
        continue;
      }

      try {
        replayFiles.push(await (await replayDirHandle.getFileHandle(selectedKey)).getFile());
      } catch (e) {
        console.log(e);
        this.error = `Could not get replay file "${selectedKey}"`;
        return;
      }
    }

    try {
      await ReplayService.uploadReplays(this.match.slug, etternaXML, replayFiles);
    } catch (e) {
      console.log(e);
      this.error = 'Could not upload replay data';
      return;
    }

    this.state = 'uploadDone';
  }

  public uploadBack(): void {
    this.state = 'choosing';
  }
}
</script>

<style scoped lang="scss">
.score {
  &.active {
    border: 2px solid white;
  }

  border: 2px solid transparent;
}

.next-button {
  &:disabled {
    @apply bg-green-200;
  }
}
</style>
